<template>
    <OMediaQueryProvider v-slot="{ isMobile }">
        <div>
            <BasicFilter :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown" :hideOperators="hideOperators"></BasicFilter>

        </div>
    </OMediaQueryProvider>
</template>

<script setup>
    import BasicFilter from 'o365.vue.components.FilterEditor.Basic.vue';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    
    const props = defineProps({
        filterItem: null,
        filterObject: null,
        dropdown: null,
        hideOperators:null
    });

  
</script>
